import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';

const items = [
  {
    icon: <SettingsSuggestRoundedIcon />,
    title: 'Custom LLM Solutions',
    description:
        'Whether Fine tuning, LoRa, MLOps or more, we have you covered.',
  },
  {
    icon: <ConstructionRoundedIcon />,
    title: 'Support',
    description:
      'Our dedicated engineers will keep you up to date and online.',
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: 'Human Reinforcement Learning',
    description:
      'Looking to add Human-in-the-loop capabilities to your product?',
  },
  {
    icon: <AutoFixHighRoundedIcon />,
    title: 'Unstructured Data',
    description:
      'Leverage our LLM-powered parsing solutions to manage unstructured data with high precision.',
  },
  {
    icon: <SupportAgentRoundedIcon />,
    title: 'Chatbots',
    description:
      'Use our LLM-powered chatbots to engage with your customers in real-time.',
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: 'Metrics',
    description:
      'Monitor your GenAI deployments in real-time to provide actionable insights.',
  },
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: 'grey.900',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4" gutterBottom>
            Highlights
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
            Explore why our product stands out: adaptability, durability,
            user-friendly design, and innovation. Enjoy reliable customer support and
            precision in every detail.
          </Typography>
        </Box>
        <Grid container spacing={3}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100%', // Make sure the card takes the full height
                  p: 3,
                  bgcolor: 'grey.800',
                  color: 'inherit',
                  borderColor: 'hsla(220, 25%, 25%, 0.3)',
                }}
              >
                <Stack spacing={2} sx={{ flexGrow: 1 }}>
                  <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                  <Typography gutterBottom sx={{ fontWeight: 'medium' }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </Stack>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

