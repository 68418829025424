import * as React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import LogoCollection from './components/LogoCollection';
import Highlights from './components/Highlights';
import Features from './components/Features';
import Footer from './components/Footer';
import getMPTheme from './theme/getMPTheme';
import { Box } from '@mui/material';

export default function MarketingPage() {
  const [mode, setMode] = React.useState('light');
  const featuresRef = React.useRef(null);
  const highlightsRef = React.useRef(null);
  const heroRef = React.useRef(null);

  React.useEffect(() => {
    const savedMode = localStorage.getItem('themeMode');
    if (savedMode) {
      setMode(savedMode);
    } else {
      const systemPrefersDark = window.matchMedia(
        '(prefers-color-scheme: dark)'
      ).matches;
      setMode(systemPrefersDark ? 'dark' : 'light');
    }
  }, []);

  const toggleColorMode = () => {
    const newMode = mode === 'dark' ? 'light' : 'dark';
    setMode(newMode);
    localStorage.setItem('themeMode', newMode);
  };

  const scrollToFeatures = () => {
    featuresRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToHighlights = () => {
    highlightsRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToHero = () => {
    heroRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const MPTheme = createTheme(getMPTheme(mode));

  return (
    <ThemeProvider theme={MPTheme}>
      <CssBaseline enableColorScheme />
      <AppAppBar
        mode={mode}
        toggleColorMode={toggleColorMode}
        scrollToFeatures={scrollToFeatures}
        scrollToHighlights={scrollToHighlights}
        scrollToHero={scrollToHero}
      />
      <Box sx={{ mt: 1 }} ref={heroRef}> {/* Ensure margin from the top of the page to avoid overlap */}
        <Hero />
      </Box>
      <div>
        <LogoCollection />
        <div ref={featuresRef}>
          <Features />
        </div>
        <div ref={highlightsRef}>
          <Highlights />
        </div>
        <Footer />
      </div>
    </ThemeProvider>
  );
}