import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Sitemark from './SitemarkIcon';
import ToggleColorMode from './ToggleColorMode'; // Import the ToggleColorMode component

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexShrink: 0,
  borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
  backdropFilter: 'blur(24px)',
  border: '1px solid',
  borderColor: theme.palette.divider,
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  boxShadow: theme.shadows[1],
  padding: '8px 12px',
  maxWidth: '100%', // Ensure the toolbar does not exceed the viewport width
  overflowX: 'hidden', // Prevent horizontal scrolling
}));

const Logo = styled('div')(({ theme }) => ({
  width: 60, // Set a fixed width
  height: 60, // Set a fixed height
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'contain', // Maintain aspect ratio
  },
}));

export default function AppAppBar({ mode, toggleColorMode, scrollToFeatures, scrollToHighlights, scrollToHero }) {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <AppBar
      position="fixed"
      sx={{ boxShadow: 0, bgcolor: 'transparent', backgroundImage: 'none', maxWidth: '100%', overflowX: 'hidden' }}
    >
      <Container maxWidth="lg">
        <StyledToolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', px: 0 }}>
            <Logo>
              <Sitemark />
            </Logo>
            <Box sx={{ display: { xs: 'none', md: 'flex' }, ml: 2 }}>
              <Button variant="text" color="info" size="small" onClick={scrollToFeatures}>
                Offerings
              </Button>
              <Button variant="text" color="info" size="small" onClick={scrollToHighlights}>
                Highlights
              </Button>
            </Box>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
            <Button variant="contained" color="primary" size="small" onClick={scrollToHero}>
              Sign Up
            </Button>
            <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} sx={{ ml: 1 }} />
          </Box>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{ display: { xs: 'flex', md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
            <Box
              sx={{ width: 250 }}
              role="presentation"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              <IconButton onClick={toggleDrawer(false)}>
                <CloseRoundedIcon />
              </IconButton>
              <Divider />
              <MenuItem onClick={scrollToFeatures}>Offerings</MenuItem>
              <MenuItem onClick={scrollToHighlights}>Highlights</MenuItem>
              <MenuItem onClick={scrollToHero}>Sign Up</MenuItem>
              <MenuItem onClick={toggleColorMode}>Toggle Theme</MenuItem>
            </Box>
          </Drawer>
        </StyledToolbar>
      </Container>
    </AppBar>
  );
}