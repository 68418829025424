import * as React from 'react';
import Box from '@mui/material/Box';
import logo from './assets/itclogo.png'; // Make sure to provide the correct path to your logo file

export default function SitemarkIcon() {
  return (
    <Box sx={{ height: 60, width: 60, mr: 2 }}>
      <img src={logo} alt="Sitemark Logo" style={{ height: '100%', width: '100%' }} />
    </Box>
  );
}
