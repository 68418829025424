import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';
import snowflakelight from './assets/logos/snowflakelight.svg';
import workatolight from './assets/logos/Workatolight.svg';
import databrickslight from './assets/logos/databrickslight.svg';
import databricksdark from './assets/logos/databricksdark2.svg';
import azurelight from './assets/logos/azurel.svg';
import azuredark from './assets/logos/azured.svg';
import awsd from './assets/logos/awsd.svg';
import awsl from './assets/logos/awsl.svg';
import docker from './assets/logos/docker.svg';

// Replace with actual URLs or local paths for the logos
const whiteLogos = [
  awsl, // AWS white logo
  workatolight,  // Workato white logo
  docker,       // Docker white logo
  snowflakelight,// Snowflake white logo
  databricksdark, // Databricks white logo
  azuredark, // Azure white logo
];

const darkLogos = [
  awsd, // AWS dark logo
  workatolight,  // Workato dark logo
  docker,       // Docker dark logo
  snowflakelight,// Snowflake dark logo
  databrickslight, // Databricks dark logo
  azurelight, // Azure dark logo
];

const logoStyle = {
  width: '100px',
  height: '80px',
  margin: '0 32px',
  opacity: 0.7,
};

export default function LogoCollection() {
  const theme = useTheme();
  const logos = theme.palette.mode === 'light' ? darkLogos : whiteLogos;

  return (
    <Box id="logoCollection" sx={{ py: 4 }}>
      <Typography
        component="p"
        variant="subtitle2"
        align="center"
        sx={{ color: 'text.secondary' }}
      >
        AWS Architectures, Workato integrations, Docker, Snowflake, Databricks, and more.
      
      </Typography>
      <Grid container sx={{ justifyContent: 'center', mt: 0.5, opacity: 0.6, margin: 5 }}>
        {logos.map((logo, index) => (
          <Grid item key={index}>
            <img
              src={logo}
              alt={`Logo ${index + 1}`}
              style={logoStyle}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

