// eslint-disable-next-line
import logo from './logo.svg';
import './App.css';
import MarketingPage from './MarketingPage'; // Ensure this path is correct

function App() {
  return (
    <div className="App">
      

      {/* Render the MarketingPage component below the header */}
      <MarketingPage />

    </div>
  );
}

export default App;


