import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress'; // For loading spinner
import { styled } from '@mui/material/styles';
import logo from './assets/itclogo.png';
import heroImage from './assets/heroimg1.png'; // Import your image

const StyledBox = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  width: '100%',
  height: 400,
  marginTop: theme.spacing(8),
  borderRadius: theme.shape.borderRadius,
  outline: '6px solid',
  outlineColor: 'hsla(12, 70%, 64%, 0.2)',
  border: '1px solid',
  borderColor: '#dd7464',
  boxShadow: '0 0 12px 8px hsla(12, 70%, 64%, 0.2)',
  overflow: 'hidden',
  position: 'relative',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(10),
    height: 700,
  },
  ...theme.applyStyles('dark', {
    boxShadow: '0 0 24px 12px hsla(12, 70%, 64%, 0.2)',
    outlineColor: 'hsla(12, 70%, 64%, 0.1)',
    borderColor: '#dd7464',
  }),
}));

export default function Hero() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
  
    // Simple email validation client-side
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address.');
      return;
    }
  
    setLoading(true);
  
    try {
      const response = await fetch('https://iuqnmj1rkb.execute-api.us-east-1.amazonaws.com/prod/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        setSuccess(data.message || 'Subscription successful!');
      } else {
        setError(data.message || 'An error occurred, please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('There was a network error. Please try again later.');
    }
  
    setLoading(false);
  };

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage:
          'radial-gradient(ellipse 80% 50% at 50% -20%, #dd7464, transparent)',
        ...theme.applyStyles('dark', {
          backgroundImage:
            'radial-gradient(ellipse 80% 50% at 50% -20%, #4a2943, transparent)',
        }),
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
          px: { xs: 2, md: 4 },
        }}
      >
        <Stack
          spacing={2}
          useFlexGap
          sx={{ alignItems: 'center', width: { xs: '100%', sm: '70%' } }}
        >
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              fontSize: 'clamp(2.5rem, 6vw, 3.5rem)',
              textAlign: 'center',
            }}
          >
            <img src={logo} alt="ITC Logo" style={{ height: 100, width: 100 }} />
            IT Consultants Inc.
          </Typography>
          <Typography
            sx={{
              textAlign: 'center',
              color: 'text.secondary',
              width: { sm: '100%', md: '80%' },
              fontSize: { xs: '1rem', md: '1.25rem' },
            }}
          >
            Discover the next generation of AI tools—RAG, intelligent agents, and more. 
            Be the first to explore cutting-edge innovations as we expand our offerings. 
            Join our list and stay informed about the latest advancements.
          </Typography>
        </Stack>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mt: 4,
          }}
        >
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={1}
            useFlexGap
            sx={{ width: '100%', maxWidth: 400 }}
          >
            <TextField
              variant="outlined"
              placeholder="Enter your email"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={Boolean(error)}
              helperText={error}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Subscribe'}
            </Button>
          </Stack>
          {success && (
            <Typography
              sx={{
                textAlign: 'center',
                color: 'green',
                mt: 2,
              }}
            >
              {success}
            </Typography>
          )}
        </Box>
        <Typography
          sx={{
            textAlign: 'center',
            color: 'text.secondary',
            mt: 2,
          }}
        >
            Be the first to know when new AI tools are available!

        </Typography>

        {/* StyledBox with Image Inside */}
        <StyledBox id="image">
          <img
            src={heroImage}
            alt="Hero"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: '110%',
              height: '110%',
              transform: 'translate(-50%, -50%)',
              objectFit: 'cover',
              borderRadius: 'inherit',
            }}
          />
        </StyledBox>
      </Container>
    </Box>
  );
}
